import React from "react"
import "./text.module.scss"
import headerStyles from "./text.module.scss"

const Text = (props) => {

	return (

        <div className={ `uk-section uk-section-small ${headerStyles.TextMobileMargin}` }>
            <div className={ `uk-container` }>
                <div className={ `uk-grid-match uk-child-width-1-1@l` } uk-grid="true">

                    <h2 className={ `${headerStyles.TextHeader}` }>{props.title}</h2>

                    <div className={ `uk-margin-small-top` }>
                        <div className={ `uk-flex uk-flex-column uk-flex-left` } >
                            
                            {props.html}

                        </div>
                    </div>

                    <div className={ `uk-section.uk-section-xsmall ` }>
						<div className={ `uk-container` }>
							<div className={ `uk-child-width-1-1@s uk-child-width-1-1@m uk-grid-match uk-child-width-1-2@l uk-child-width-1-2@xl` } uk-grid="true">
						
								<div><iframe src="https://www.youtube.com/embed/sqdBAdr_RhE" height="300px"></iframe></div>
								<div></div>

							</div>
						</div>
					</div>
                
                </div>
            </div>
        </div>
        

	)
}

export default Text


