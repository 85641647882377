import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { ProductJsonLd } from 'gatsby-plugin-next-seo';

import "./blog.module.scss"
import headerStyles from "./blog.module.scss"

import Seo from "../components/seo"
import Header from "../components/header"
import Story from "../components/story"
import Sdelka  from "../components/sdelka"
import Price from "../components/price"
import Catalog from "../components/catalog"
import Uryslygi from "../components/uryslygi"
import OtzivAudio from "../components/otzivAudio"
import OtzivVideo from "../components/otzivVideo"
import OtzivSocial from "../components/otzivSocial"
import OtzivBlank from "../components/otzivBlank"
import OtzivMap from "../components/otzivMap"


import Social from "../components/social"
import Certificate from "../components/certificate"
import Users from "../components/users"
import Blogs from "../components/blog"
import Text from "../components/text2"


import Map from "../components/map"


import ContactForm from "../components/contactForm"
import Call from "../components/call"
import Sale from "../components/sale"
import CallForm from "../components/callForm"
import Stado from "../components/stado"

export const query = graphql `
	query($slug: String!) {
		uslugi: uslugi(slug: { eq: $slug }) {
			slug
			title
			description
			card
			datePublished
        	seoTitle
			seoDescription
		}

		blogComponent: allBlog(sort: { order: DESC, fields: datePublished }, limit: 10) {
			edges {
			  node {
				slug
				title
				description
				card
				img
				imgMini
				date
				seoTitle
				seoDescription
				author
				ava
				time
			  }
			} 
		}

	}
`
// data={data.blogComponent}
const Uslugi = ({data}) => {
	const merch = data.uslugi
	const blogs = data.blogComponent
	
	return (
		<Layout>
			<Seo title={merch.seoTitle} description={merch.seoDescription} />

			<Header title={merch.title} card={merch.card} />
			<ProductJsonLd
				productName={merch.title}
				images={[
					`https://юридические-услуги-казань.рф/img/blog/blog1.png`,
				]}
				description={merch.seoDescription}
				brand='Алмаз Казань'
				reviews={
					[
						{"name":"Вадим", 
							"date":"22.05.2021",
							"rate":"5",
							"review":"Очень обязан Вам."
						},
						{"name":"Олег", 
							"date":"22.05.2021",
							"rate":"5",
							"review":"Без Вас я бы не справился!"
						},
						{"name":"Марина", 
							"date":"22.05.2021",
							"rate":"5",
							"review":"Спасибо Вам"
						},
						{"name":"Лиза", 
							"date":"22.02.2021",
							"rate":"5",
							"review":"Благодарю от души."
						}
					]
				}
				aggregateRating={{
					ratingValue: '5',
					reviewCount: '4',
				}}
				offers={{
					price: '5000',
					priceCurrency: merch.datePublished,
					priceValidUntil: merch.datePublished,
					itemCondition: 'http://schema.org/UsedCondition',
					availability: 'http://schema.org/InStock',
					url: `https://юридические-услуги-казань.рф/uslugi/${merch.slug}`,
					seller: {
						name: merch.title,
					},
				}}
			/>

			<Story />
			<Uryslygi />
			<Sdelka />
			<Price />
			<Catalog />

			<OtzivAudio />
			<OtzivVideo />
			<OtzivSocial />
			<OtzivBlank />
			<OtzivMap />


			<Social />
			<Certificate />
			<Users />

			<Blogs data={blogs} />

			<Text 
				title={merch.title} html={merch.description}
			/>

			<Map />
		
			<ContactForm />
			<Sale />
			<Call />
			<CallForm />
			<Stado />
		</Layout>
	)
}


export default Uslugi
